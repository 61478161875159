import { Flex, Text } from '@chakra-ui/react';
import Button from '@ui-kit/atoms/button/button';
import { useTranslations } from 'next-intl';
import { FC } from 'react';
import hardPush from '@/shared/utils/hard-push';

type Props = {
  onSignIn: () => void;
  isAuthenticated: boolean;
};

export const HomePresent: FC<Props> = ({ onSignIn, isAuthenticated }) => {
  const t = useTranslations();

  return (
    <Flex
      p={{ base: '65px 20px 20px', lg: '40px' }}
      m='0 auto'
      mt={{ base: '-65px', lg: 0 }}
      minH={{ base: '553px', lg: '783px' }}
      bgImage={'/images/home/image-section-present.png'}
      bgColor='#0F0D08'
      bgRepeat='no-repeat'
      bgPos='top 0% right 0%'
      bgSize={{ base: 'cover', lg: '50%' }}
      direction='column'
      justify={{ base: 'flex-end', lg: 'space-between' }}
      rounded='30px'
      overflow='hidden'
    >
      <Text
        as='h1'
        maxW='800px'
        textStyle={{ base: 'heading-small-3xl', lg: 'heading-large-4xl' }}
      >
        {t('HomePresent.title')}
        <br />
        {t('HomePresent.slogan')}
      </Text>

      <Flex
        direction='column'
        justify={{ base: undefined, lg: 'space-between' }}
        w={{ base: '248px', lg: '560px' }}
        ml={{ base: 'auto', lg: 0 }}
        mt={{ base: '40px', lg: '60px' }}
        flex={{ base: undefined, lg: 1 }}
      >
        <Text textStyle={{ base: 'text-regular-sm', lg: 'text-regular-xl' }}>
          {t('HomePresent.description')}
        </Text>

        <Button
          variant='solid'
          colorScheme='green'
          w={{ base: 'full', lg: '291px' }}
          size='md'
          mt={{ base: '20px', lg: 0 }}
          onClick={isAuthenticated ? () => hardPush('/campaigns') : onSignIn}
        >
          <Text textStyle='heading-large-sm'>
            {t('HomePresent.getStarted')}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default HomePresent;
