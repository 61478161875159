import { LIMIT, OFFSET, REVALIDATE } from '@/entities/constants';
import { demuzoApi } from '@/shared/api';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { newsKeys } from '../query-keys';
import { getQueryClient } from '@/shared/utils/get-query-client';

type Props = {
  limit: number;
  offset: number;
};

export const getNewsPrefetchConfig = () =>
  ({
    queryKey: [...newsKeys.getNews({ limit: LIMIT, offset: OFFSET })],
    queryFn: () =>
      demuzoApi.getNews(
        { limit: LIMIT, offset: OFFSET },
        { next: { revalidate: REVALIDATE } },
      ),
  }) satisfies FetchQueryOptions;

export const useGetNews = ({ limit, offset }: Props) => {
  return useQuery({
    queryKey: [...newsKeys.getNews({ limit, offset })],
    queryFn: () => demuzoApi.getNews({ offset, limit }),
  });
};
