'use client';

import { getNewsPrefetchConfig, useGetNews } from '@/entities/news';
import { getProductsAllPrefetchConfig } from '@/entities/product';
import { useDeleteFavorite } from '@/entities/user/model/hooks/use-delete-favorite';
import { usePostFavorite } from '@/entities/user/model/hooks/use-post-favorite';
import { usePlayList, usePlayerControls } from '@/features/audio-player';
import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import { CrowdFundingCampaignStatusEnum } from '@/shared/api/codegen/demuzo';
import { FaqBlock } from '@/shared/components/faq-block';
import HomeHowItWorks from '@/shared/components/home-how-it-works/home-how-it-works';
import { HomePartners } from '@/shared/components/home-partners';
import { HomePresent } from '@/shared/components/home-present';
import { NewsSwiper } from '@/shared/components/news-swiper';
import { IconButton } from '@/shared/ui-kit/atoms/icon-button';
import { useIsMobile } from '@/shared/ui-kit/hooks/useIsMobile';
import IconChevronLeft from '@/shared/ui-kit/icons/icon-chevron-left';
import IconChevronRight from '@/shared/ui-kit/icons/icon-chevron-right';
import { Section } from '@/shared/ui-kit/molecules/section';
import { CampaignCard } from '@/shared/ui-kit/organisms/campaign-card';
import { ProjectCard } from '@/shared/ui-kit/organisms/project-card';
import {
  calculateDaysLeft,
  calculateProgressStartDay,
} from '@/shared/utils/calculate-days-left';
import hardPush from '@/shared/utils/hard-push';
import { Link } from '@chakra-ui/next-js';
import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { MouseEvent, useCallback, useState } from 'react';
import { Swiper } from 'swiper/types';
import FeaturedWidget from '../../widgets/featured-widget/featured-widget';
import { useTranslations } from 'next-intl';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

export const HomePage = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const t = useTranslations();
  const isMobile = useIsMobile();

  const [controlledSwiperNews, setControlledSwiperNews] =
    useState<Swiper | null>(null);

  const { currentTrack } = usePlayList();
  const { data: playerData, updateData } = usePlayerData();
  const { push } = useRouter();
  const mutationSubscribe = usePostFavorite();
  const mutationUnSubscribe = useDeleteFavorite();

  const { data: dataProducts } = useQuery(getProductsAllPrefetchConfig());
  const { data: dataNews } = useQuery(getNewsPrefetchConfig());

  const handleSignIn = async () => {
    await signIn('keycloak');
  };

  const handleToggleFavoriteFeature = ({
    evt,
    productId,
    isFavorite,
    isLogged,
  }: {
    evt: MouseEvent<HTMLButtonElement>;
    productId: string;
    isFavorite: boolean;
    isLogged: boolean;
  }) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!isLogged) {
      handleSignIn();
      return;
    }

    if (!isFavorite) {
      mutationSubscribe.mutate(productId);
      return;
    }

    mutationUnSubscribe.mutate(productId);
  };

  const onAllCampaigns = () => {
    push('/campaigns');
  };

  const onBuyClick = useCallback(
    async (id: string) => {
      if (!isAuthenticated) {
        await signIn('keycloak');
        return;
      }

      push(`/project/purchase/${id}`);
    },
    [isAuthenticated, push],
  );

  return (
    <Box>
      <HomePresent
        isAuthenticated={isAuthenticated}
        onSignIn={handleSignIn}
      />

      <Section
        mt={{ lg: '100px' }}
        withPadding={isMobile}
        header={t('HomePage.howItWorksTitle')}
      >
        <Box mt={{ xl: '-50px' }}>
          <HomeHowItWorks />
        </Box>
      </Section>

      <FeaturedWidget />

      <Section
        isVisible={Boolean(dataProducts?.length)}
        withPadding={isMobile}
        header={t('HomePage.campaigns')}
        mt={{ lg: '100px' }}
      >
        <Box
          mt={{ xl: '-50px' }}
          maxW='1220px'
          ml='auto'
          w='full'
        >
          <SimpleGrid
            columns={{ base: 1, md: 3, lg: 4 }}
            spacingX={{ base: '10px', md: '20px' }}
            spacingY={{ base: '10px', md: '60px' }}
          >
            {dataProducts?.map((product) => {
              const snippet = product.snippets.at(0);

              const isCurrentTrack = snippet && snippet.id === currentTrack?.id;
              const needUpdateData =
                playerData?.isFavoriteCampaign !== product.isFavorite;
              if (isCurrentTrack && needUpdateData) {
                updateData({ isFavoriteCampaign: product.isFavorite });
              }

              const handleCurrentPlay = () => {
                updateData({
                  currentCampaignId: product.id,
                  isFavoriteCampaign: product.isFavorite,
                  canBuy: product.crowdFundingCampaign.status === 'in_progress',
                });
              };

              return isMobile ? (
                <ProjectCard
                  href={`/project/${product.id}`}
                  key={product.id}
                  snippet={snippet}
                  imageUrl={product.coverUrls.at(0) ?? ''}
                  onPlay={handleCurrentPlay}
                  onToggleFavoriteFeature={(evt) => {
                    handleToggleFavoriteFeature({
                      evt,
                      productId: product.id,
                      isFavorite: product.isFavorite,
                      isLogged: true,
                    });
                  }}
                  isFavorite={product.isFavorite}
                  daysLeft={calculateDaysLeft(
                    product.crowdFundingCampaign.startDateTime,
                    product.crowdFundingCampaign.endDateTime,
                  )}
                  progress={Number(product.crowdFundingCampaign.progress)}
                  artistName={product.artist.name}
                  productName={product.name}
                  genres={`${product.primaryGenre} · ${product.secondaryGenre}`}
                  totalInvested={product.crowdFundingCampaign.totalInvested}
                  limit={product.crowdFundingCampaign.successThreshold}
                  crowdFundingCampaignStatus={
                    product.crowdFundingCampaign.status
                  }
                  progressToStart={
                    product.crowdFundingCampaign.createdAt
                      ? calculateProgressStartDay(
                          product.crowdFundingCampaign.createdAt,
                          product.crowdFundingCampaign.startDateTime,
                        )
                      : 1
                  }
                />
              ) : (
                <CampaignCard
                  isButtonDivider
                  sharePrice={product.crowdFundingCampaign.sharePrice}
                  snippet={snippet}
                  withBg={false}
                  rounded={false}
                  size='sm'
                  href={`/project/${product.id}`}
                  key={product.id}
                  imageUrl={product.coverUrls.at(0) ?? ''}
                  onPlay={handleCurrentPlay}
                  onToggleFavoriteFeature={(evt) => {
                    handleToggleFavoriteFeature({
                      evt,
                      productId: product.id,
                      isFavorite: product.isFavorite,
                      isLogged: true,
                    });
                  }}
                  canBuy={
                    product.crowdFundingCampaign.status ===
                    CrowdFundingCampaignStatusEnum.in_progress
                  }
                  onBuy={() => {
                    onBuyClick(product.id);
                  }}
                  isFavorite={product.isFavorite}
                  totalInvested={product.crowdFundingCampaign.totalInvested}
                  daysLeft={calculateDaysLeft(
                    product.crowdFundingCampaign.startDateTime,
                    product.crowdFundingCampaign.endDateTime,
                  )}
                  limit={product.crowdFundingCampaign.successThreshold}
                  progress={Number(product.crowdFundingCampaign.progress)}
                  crowdFundingCampaignStatus={
                    product.crowdFundingCampaign.status
                  }
                  progressToStart={
                    product.crowdFundingCampaign.createdAt
                      ? calculateProgressStartDay(
                          product.crowdFundingCampaign.createdAt,
                          product.crowdFundingCampaign.startDateTime,
                        )
                      : 1
                  }
                  artistName={product.artist.name}
                  productName={product.name}
                  genres={`${product.primaryGenre} · ${product.secondaryGenre}`}
                  avatarUrl={product.artist.avatarUrl}
                />
              );
            })}
          </SimpleGrid>

          <Flex
            mt={{ lg: '80px' }}
            p={{ base: '20px', lg: 0 }}
            gap='20px'
            align='center'
            w='full'
            px={{ lg: 0 }}
          >
            <Button
              variant='solid'
              colorScheme='white'
              w={{ base: 'full', lg: 'auto' }}
              size={{ base: 'md', lg: 'lg' }}
              onClick={onAllCampaigns}
            >
              <Text textStyle='heading-large-sm'>
                {t('HomePage.allCampaignsAction')}
              </Text>
            </Button>

            {!isMobile ? (
              <Text
                textStyle='text-regular-lg'
                color='text&icon.tx-secondary'
              >
                {t('HomePage.allCampaignsActionTitle')}
              </Text>
            ) : null}
          </Flex>
        </Box>
      </Section>

      <HomePartners />

      <Section
        isVisible={Boolean(dataNews?.items.length)}
        mt={{ base: '20px', lg: '40px' }}
        withPadding={isMobile}
        header={t('HomePage.latestNewsTitle')}
        rightAction={
          isMobile ? (
            <Link href='/news'>
              <Text textStyle='text-medium-lg'>
                {t('HomePage.latestNewsAll')}
              </Text>
            </Link>
          ) : (
            <Flex gap='20px'>
              <IconButton
                aria-label='back'
                variant='transparent'
                icon={<IconChevronLeft />}
                onClick={() => controlledSwiperNews?.slidePrev()}
              />
              <IconButton
                aria-label='next'
                variant='transparent'
                icon={<IconChevronRight />}
                onClick={() => controlledSwiperNews?.slideNext()}
              />
            </Flex>
          )
        }
      >
        <NewsSwiper
          newsData={dataNews?.items || []}
          controlledSwiperNews={controlledSwiperNews || undefined}
          setControlledSwiperNews={setControlledSwiperNews}
        />
      </Section>

      <FaqBlock />
    </Box>
  );
};

export default HomePage;
